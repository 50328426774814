import { Dimension, Quantity } from "dimtext";
import { nextFabMark } from "@local/extensions/identifiers/mark.js";
import type { GroupRecordCore } from "./group.js";
import type { OrgRecordCore } from "./org.js";
import { times } from "overline/iterable";
import { orderedList } from "@local/extensions/collections/sortable-list.js";

interface Features {
  edge?: Array<object>;
  voids?: Array<Void>;
  corner?: Array<object>;
  edge_treatment?: Array<object>;
  bug?: object;
  additive?: Array<object>;
}

interface PositionReference {
  edge: number;
  loop: number;
  type: "edge-offset-position";
  start: "start" | "end";
  length: Dimension;
  offset: Dimension;
}

type Void = CircularHole | RectangularHole;

const FAB_CUT = ["edge", "voids", "corner"] as const;
const FAB_EDGEWORK = ["edge_treatment"] as const;
const FAB_ANY = [...FAB_CUT, ...FAB_EDGEWORK];

// TODO: how to classify bug?

interface Fabricated {
  data: {
    fabrications?: Features;
  };
}

function hasFabrication(types: Readonly<typeof FAB_ANY>, item: Fabricated) {
  const { fabrications } = item.data;

  return fabrications
    ? types.some((type) => (fabrications[type]?.length ?? 0) > 0)
    : false;
}

function hasCutFabrication(item: Fabricated) {
  return hasFabrication(FAB_CUT, item);
}

function createEdgeFabrication(fabrications) {
  const fabs = orderedList(fabrications);
  const efab = fabs.find((f) => f.position === "edge");
  return {
    type: "edge-fabrication",
    fab_id: efab?.id,
    reference: {
      type: "edge-position",
      loop: 0,
      edge: 0,
      start: "start",
      length: new Dimension(new Quantity(0, "inches")),
    },
  };
}

function createCornerFabrication(fabrications) {
  const fabs = orderedList(fabrications);
  const cfab = fabs.find((f) => f.position === "corner");
  return {
    type: "corner-fabrication",
    fab_id: cfab?.id,
    reference: {
      type: "corner-position",
      loop: 0,
      corner: 0,
    },
  };
}

interface CircularHole {
  type: "circular-hole";
  diameter: Dimension;
  reference: PositionReference;
}

function createCircularHole(): CircularHole {
  return {
    type: "circular-hole",
    diameter: new Dimension(new Quantity(1, "inches")),
    reference: {
      type: "edge-offset-position",
      loop: 0,
      edge: 0,
      start: "start",
      length: new Dimension(new Quantity(0, "inches")),
      offset: new Dimension(new Quantity(0, "inches")),
    },
  };
}

interface RectangularHole {
  type: "rectangular-hole";
  width: Dimension;
  height: Dimension;
  radius: Dimension;
  reference: PositionReference;
  alignment: "inside-edge";
  orientation: "orthogonal";
}

function createRectangularHole(): RectangularHole {
  return {
    type: "rectangular-hole",
    width: new Dimension(new Quantity(3, "inches")),
    height: new Dimension(new Quantity(2, "inches")),
    radius: new Dimension(new Quantity(0, "inches")),
    alignment: "inside-edge",
    orientation: "orthogonal",
    reference: {
      type: "edge-offset-position",
      loop: 0,
      edge: 0,
      start: "start",
      length: new Dimension(new Quantity(0, "inches")),
      offset: new Dimension(new Quantity(0, "inches")),
    },
  };
}

function createVirtualFabrications({
  hole = 0,
  edge = 0,
  corner = 0,
}: {
  hole: number;
  edge: number;
  corner: number;
}) {
  const holeFab = () => createCircularHole();

  const cornerFab = () => ({
    type: "corner-fabrication",
    fab_id: null,
    reference: {
      type: "corner-position",
      loop: 0,
      corner: 0,
    },
  });

  const edgeFab = () => ({
    type: "edge-fabrication",
    fab_id: null,
    reference: {
      type: "edge-position",
      edge: 0,
      loop: 0,
      start: "start",
      length: Dimension.from(1, "inches"),
    },
  });

  const virtual = [
    ...times(hole, holeFab),
    ...times(edge, edgeFab),
    ...times(corner, cornerFab),
  ];

  return virtual;
}

type Context = {
  group: GroupRecordCore;
  supplier: OrgRecordCore;
}

function createOrgFabrication(id: string, context: Context) {
  const { group, supplier } = context;
  const groupFabs = orderedList(group.data.fabrications);
  const mark = nextFabMark(groupFabs);
  const fab = supplier.fabrications[id];

  return {
    ...fab.data,
    id: crypto.randomUUID(),
    vendor_id: fab.vendor_id,
    product_id: id,
    name: fab.name,
    mark,
  };
}

export type { Fabricated, Features, Void };

export {
  createEdgeFabrication,
  createCornerFabrication,
  createCircularHole,
  createRectangularHole,
  createVirtualFabrications,
  createOrgFabrication,
  hasFabrication,
  hasCutFabrication,
};
