import { Drawing } from "drawing";
import { midpoint, scale, rotate, normalize, subtract } from "vector";

export function edgeAligned(fab, instance, edges) {
  const edge = edges[instance.reference.loop]?.[instance.reference.edge];
  const point = midpoint(edge.start, edge.end);
  const dir = normalize(subtract(edge.end, edge.start));
  const shift = scale(rotate(dir, Math.PI / 2), 5);

  const annotations = new Drawing()
    .polyline([edge.start, edge.end])
    .style({
      stroke: "#333",
      fill: "transparent",
      lineWidth: 1,
    })
    .shift({ x: shift.x, y: shift.y });

  return {
    point,
    path: null,
    voids: [],
    dims: () => [],
    annotations,
  };
}
