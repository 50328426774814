import {
  sortableList,
  sortArrayBy,
} from "@local/extensions/collections/sortable-list.js";
import { Dimension } from "dimtext";

import { objectify } from "overline";

import { hydrateType } from "./type.js"
import { hydrateMaterial } from "./material.js";

import type { CustomColumns, StandardColumn } from "./column.js";
import type { OrderedMap } from "../ordered-map.js"



interface OrgRecordCore {
  data: {
    logo?: object;
    custom_columns?: CustomColumns;
    standard_columns?: OrderedMap<string, StandardColumn>;
    settings?: object;
    makeup_order?: object;
    banner?: object;
    material_order?: object;
    type_order?: object;
    deleted_columns?: object;
    surface_order?: object;
    fabrication_order?: object;
  }
}


function createOrg() {
  return {
    name: "Org Name",
    reference: null,
    address: "123 Main Street\nAnytown, USA 12345\n555-555-5555",
    org_type: "supplier",
    data: {
      settings: {
        dimension_format: "FRACTIONAL",
        decimal_precision: 2,
        display_unit: "inches",
        mm_precision: 0,
        fractional_precision: 4,
        label_by: "mark",
        collections_label_by: "mark",
        document_display_mode: "view",
        document_sort_by: "created_at",
        hide_width_offset: true,
        hide_height_offset: true,
      },
    },
  };
}

function hydrateOrg(org) {

  if (org.types) {
    org.types = sortableList(
      sortArrayBy(
        org.types.map((t) => hydrateType(t)),
        org.data.type_order,
      ),
    );
  }

  if (org.products) {
    org.makeups = sortableList(
      sortArrayBy(
        org.makeups
          .map((p) => ({ ...p, data: p.expanded_data }))
          .map((p) => hydrateType(p)),
        org.data.makeup_order,
      ),
    );

    org.item_products = sortableList(
      sortArrayBy(
        org.item_products,
        org.data.item_product_order,
      ),
    );

    org.materials = sortableList(
      sortArrayBy(
        org.products
          .filter((p) => p.application === "material")
          .map((p) => hydrateMaterial(p)),
        org.data.material_order,
      ),
    );

    org.surfaces = sortableList(
      sortArrayBy(
        org.products.filter((p) => p.application === "surface"),
        org.data.surface_order,
      ),
    );

    org.fabrications = sortableList(
      sortArrayBy(
        org.products.filter((p) => p.application === "fabrication")
          .map((p) => {
            if (p.data?.params) {
              p.data.params = p.data.params.map((param) => new Dimension(param));
            }
            return p;
          }),
        org.data.fabrication_order,
      ),
    );
  }

  if (org.product_lists) {
    org.product_lists.forEach((pl) => {
      if (pl.price_entries) {
        pl.price_entries = objectify(pl.price_entries, "product_id");
      }
    });
  }

  return org;
}

export type { OrgRecordCore }

export { createOrg, hydrateOrg }
