import { geneva, genevaParams } from "./geneva.js";
import { patch, patchParams } from "./patch.js";
import { cutout, cutoutParams } from "./cutout.js";
import { edge } from "./edge.js";
import { corner } from "./corner.js";
import { edgeAligned } from "./edge-aligned.js";
import { twoPoint } from "./two-point.js";

export const fabFunctions = {
  geneva,
  cutout,
  patch,
  edge,
  corner,
  "edge-aligned": edgeAligned,
  "two-point": twoPoint,
};

export const fabParams = {
  geneva: genevaParams,
  cutout: cutoutParams,
  patch: patchParams,
};
