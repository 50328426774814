import { Drawing } from "drawing";

function rectSymbol(x, y, name) {
  return new Drawing()
    .symbol(
      [
        {
          type: "polyface",
          faces: [
            [
              { x: -4, y: -4 },
              { x: 4, y: -4 },
              { x: 4, y: 4 },
              { x: -4, y: 4 },
            ],
          ],
        },
      ],
      { x, y },
    )
    .style({ stroke: "#0066E5", fill: "white", lineWidth: 2 })
    .name(name);
}

export default function clipRectangle(type, clip, id) {
  if (!clip) return null;

  const { x, y, width, height } = clip;

  const outline = new Drawing()
    .polyline([
      { x, y: -y },
      { x: x + width, y: -y },
      { x: x + width, y: -y - height },
      { x, y: -y - height },
      { x, y: -y },
    ])
    .style({ stroke: "#0066E5", fill: "transparent", lineWidth: 2 })
    .name(`${type}-clip_${id}_outline`);

  const corners = [
    rectSymbol(x, -y - height, `${type}-clip_${id}_0`),
    rectSymbol(x + width, -y - height, `${type}-clip_${id}_1`),
    rectSymbol(x + width, -y, `${type}-clip_${id}_2`),
    rectSymbol(x, -y, `${type}-clip_${id}_3`),
  ];

  return new Drawing().add(outline, ...corners);
}
