import canvas from "./canvas.js";
import segmentList from "./segment-list.js";
import svg from "./svg.js";
import jspdfNative from "./jspdf-native.js";
// import pdfkit from "./pdfkit.js";

const CONTEXTS = {
  canvas,
  svg: svg,
  "segment-list": segmentList,
  "jspdf-native": jspdfNative,
  // pdfkit,
};

function defaultStyles() {
  return {
    fill: "#000",
    stroke: "#000",
    lineWidth: 1,
    lineDash: null,
    fontSize: 12,
    font: "Menlo",
    fontWeight: "normal",
    fillRule: "evenodd",
    textAlign: "center",
    annoScale: 1,
  };
}

/**
 * @template T
 * @param {T} ctx
 * @param {keyof CONTEXTS} typeId
 */
function context(ctx, typeId) {
  let layer = "default";
  const stack = [];
  const styles = defaultStyles();

  const type = CONTEXTS[typeId] || canvas;

  return {
    path(commands, fillRule = styles.fillRule) {
      stack.push({
        type: "path",
        commands,
        fillRule,
        layer,
      });
    },

    beginPath(fillRule = styles.fillRule) {
      stack.push({
        type: "path",
        commands: [],
        fillRule,
        layer,
      });
    },

    beginMask() {
      stack.push({
        type: "mask",
        commands: [],
      });
    },

    moveTo(x, y) {
      const last = stack[stack.length - 1];
      last.commands.push({ command: "M", params: [x, y] });
    },

    lineTo(x, y) {
      const last = stack[stack.length - 1];
      last.commands.push({ command: "L", params: [x, y] });
    },

    arcTo(x, y, r, laf, sf) {
      const last = stack[stack.length - 1];
      last.commands.push({ command: "A", params: [r, r, 0, laf, sf, x, y] });
    },

    closePath() {
      const last = stack[stack.length - 1];
      last.commands.push({ command: "Z", params: [] });
    },

    closeMask() {
      stack.push({ type: "closeMask" });
    },

    circle(x, y, r, fillRule = styles.fillRule) {
      stack.push({
        type: "circle",
        x,
        y,
        r,
        fillRule,
        layer,
      });

      // stack.push({
      //   type: "path",
      //   commands: [
      //     { command: "M", params: [x - r, y] },
      //     { command: "A", params: [r, r, 0, "1", "1", x + r, y] },
      //     { command: "A", params: [r, r, 0, "1", "1", x - r, y] },
      //     { command: "Z", params: [] },
      //   ],
      //   fillRule,
      //   layer,
      // });
    },

    text(
      t,
      x,
      y,
      rotation = 0,
      annoScale = styles.annoScale,
      fill = styles.fill,
    ) {
      stack.push({
        type: "text",
        text: t,
        x,
        y,
        rotation,
        fill,
        font: styles.font,
        fontSize: styles.fontSize,
        fontWeight: styles.fontWeight,
        textAlign: styles.textAlign,
        width: styles.width,
        truncate: styles.truncate,
        annoScale,
        layer,
      });
    },

    /**
     *
     * @param {*} image
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @param {number} opacity
     * @param {string} format
     */
    image(image, x, y, width, height, opacity, format) {
      stack.push({
        type: "image",
        image,
        x,
        y,
        width,
        height,
        opacity,
        layer,
        format,
      });
    },

    setLayer(l) {
      layer = l;
    },

    style(prop, value) {
      styles[prop] = value;
    },

    stroke(s = styles.stroke) {
      const last = stack[stack.length - 1];
      last.stroke = s || styles.stroke;
      last.lineWidth = styles.lineWidth;
      last.lineDash = styles.lineDash;
    },

    fill(f = styles.fill) {
      const last = stack[stack.length - 1];
      last.fill = f;
    },

    measureText(t) {
      return type.measureText(ctx, t, styles);
    },

    play() {
      type.play(ctx, stack);
    },
  };
}

export default context;
