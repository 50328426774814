<script>
  import { createEventDispatcher } from "svelte";
  import { fromEvent } from "file-selector";
  import UploadIcon from "@local/assets/icons/upload-lg.svg";
  import SpinnerIcon from "@local/assets/icons/spinner-lg.svg";
  import { isEvtWithFiles } from "./utils.js";

  /** @type {string | null} */
  export let accept = null;
  export let text = "Drag and drop or click to upload";
  export let stream = false;
  export let multiple = false;

  const dispatch = createEventDispatcher();

  let fileInput;
  let dragcount = 0;
  let loading = false;

  export const stopLoading = () => {
    loading = false;
  };

  $: hovering = dragcount > 0;

  function clickUploader() {
    fileInput.click();
  }

  /**
   * @typedef {import('file-selector').FileWithPath} FileWithPath
   */

  /**
   *
   * @param reader {FileReader}
   * @param file {Blob}
   */
  async function returnStream(files) {
    const file = files[0];
    const s = await file.stream();
    dispatch("drop", { file, data: s });
  }

  /**
   *
   * @param reader {FileReader}
   * @param file {Blob}
   */
  function returnArraybuffer(files) {
    if (multiple) {
      const promises = [].map.call(files, (file) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        return new Promise((resolve) => {
          reader.onload = (e) => {
            resolve({ file, data: e.target.result });
          };
        });
      });

      Promise.all(promises).then((files) => {
        dispatch("drop", { files });
      });
    } else {
      const reader = new FileReader();
      const file = files[0];
      reader.readAsArrayBuffer(file);
      reader.onload = async (e) => {
        dispatch("drop", { file, data: e.target.result });
      };
    }
  }

  function handleFileSelected(evt) {
    loading = true;
    const files = evt.target.files;

    if (stream) {
      returnStream(files);
    } else {
      returnArraybuffer(files);
    }
  }

  function handleDragenter() {
    dragcount++;
  }

  function handleDragover(evt) {
    evt.preventDefault();
  }

  function handleDragleave() {
    dragcount--;
  }

  async function handleDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    dragcount = 0;

    if (isEvtWithFiles(evt)) {
      loading = true;
      const files = await fromEvent(evt);

      if (stream) {
        returnStream(files);
      } else {
        returnArraybuffer(files);
      }
    }
  }
</script>

<button
  class="w-full h-28 rounded border border-gray-300 border-dashed flex items-center cursor-pointer hover:bg-gray-100"
  class:bg-gray-100={hovering}
  on:click={clickUploader}
  on:dragenter={handleDragenter}
  on:dragover={handleDragover}
  on:dragleave={handleDragleave}
  on:drop={handleDrop}>
  <div class="text-gray-400 w-full flex flex-col items-center">
    {#if loading}
      <div class="animate-spin">
        <SpinnerIcon />
      </div>
    {:else}
      <div class="mb-2">{text}</div>
      <div>
        <UploadIcon />
      </div>
    {/if}
    <input
      class="hidden"
      type="file"
      {accept}
      bind:this={fileInput}
      on:change={handleFileSelected}
      {multiple} />
  </div>
</button>
