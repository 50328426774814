import transformPt from "../utils/transform-pt.js";

class Point {
  constructor(center, shape = "circle", size = 1) {
    this.center = center;
    this.size = size;
    this.shape = shape;
    this.type = "point";
  }

  render(options) {
    const ctx = options.ctx;
    const annoScale = options.annoScale;
    const size = this.size * annoScale * 3;
    const ctr = this.center;
    ctx.style("annoScale", annoScale);

    if (this.shape === "circle") {
      ctx.circle(ctr.x, ctr.y, size);
    } else if (this.shape === "square") {
      ctx.beginPath();
      ctx.moveTo(ctr.x - size, ctr.y - size);
      ctx.lineTo(ctr.x + size, ctr.y - size);
      ctx.lineTo(ctr.x + size, ctr.y + size);
      ctx.lineTo(ctr.x - size, ctr.y + size);
      ctx.closePath();
    } else if (this.shape === "circle-crosshairs") {
      ctx.beginPath();
      ctx.moveTo(ctr.x - size, ctr.y);
      ctx.arcTo(ctr.x + size, ctr.y, size, 1, 0);
      ctx.arcTo(ctr.x - size, ctr.y, size, 1, 0);
      ctx.moveTo(ctr.x - size * 4, ctr.y);
      ctx.lineTo(ctr.x + size * 4, ctr.y);
      ctx.moveTo(ctr.x, ctr.y - size * 4);
      ctx.lineTo(ctr.x, ctr.y + size * 4);
      ctx.closePath();
    }

    if (options.fill) {
      ctx.style("fill", options.fill);
      ctx.fill();
    }

    if (options.stroke && options.lineWidth) {
      ctx.style("stroke", options.stroke);
      ctx.style("lineWidth", options.lineWidth * annoScale);
      ctx.stroke();
    }
  }

  renderHitbox(options) {
    const ctx = options.ctx;
    const annoScale = options.annoScale;
    const size = this.size * annoScale * 3;
    const ctr = this.center;
    ctx.style("annoScale", annoScale);

    if (this.shape === "circle") {
      ctx.circle(ctr.x, ctr.y, size);
    } else if (this.shape === "square") {
      ctx.beginPath();
      ctx.moveTo(ctr.x - size, ctr.y - size);
      ctx.lineTo(ctr.x + size, ctr.y - size);
      ctx.lineTo(ctr.x + size, ctr.y + size);
      ctx.lineTo(ctr.x - size, ctr.y + size);
      ctx.closePath();
    } else if (this.shape === "circle-crosshairs") {
      ctx.beginPath();
      ctx.moveTo(ctr.x - size, ctr.y);
      ctx.arcTo(ctr.x + size, ctr.y, size, 1, 0);
      ctx.arcTo(ctr.x - size, ctr.y, size, 1, 0);
      ctx.moveTo(ctr.x - size * 4, ctr.y);
      ctx.lineTo(ctr.x + size * 4, ctr.y);
      ctx.moveTo(ctr.x, ctr.y - size * 4);
      ctx.lineTo(ctr.x, ctr.y + size * 4);
      ctx.closePath();
    }

    ctx.style("fill", options.stroke);
    ctx.fill();

    if (options.stroke && options.lineWidth) {
      ctx.style("stroke", options.stroke);
      ctx.style("lineWidth", options.lineWidth * annoScale);
      ctx.stroke();
    }

    return { pt: ctr };
  }

  get bbox() {
    return {
      xmin: this.center.x,
      xmax: this.center.x,
      ymin: this.center.y,
      ymax: this.center.y,
    };
  }

  transform(matrix) {
    const ctr = transformPt(this.center, matrix);
    return new Point(ctr, this.label);
  }
}

export default Point;
