import { transformPt } from "drawing";

export const rotations = {
  0: [Math.cos(0), Math.sin(0)],
  90: [Math.cos(-Math.PI / 2), Math.sin(-Math.PI / 2)],
  180: [Math.cos(-Math.PI), Math.sin(-Math.PI)],
  270: [Math.cos(-(3 * Math.PI) / 2), Math.sin(-(3 * Math.PI) / 2)],
};

export const translations = {
  0: [0, 0],
  90: [0, 1],
  180: [1, 1],
  270: [1, 0],
};

function normalizeAngle(angle) {
  return ((angle % 360) + 360) % 360;
}

export function rotatePoint(pt, rotation = 0) {
  rotation = normalizeAngle(rotation);
  const r = rotations[rotation] || rotations[0];
  return transformPt(pt, [r[0], r[1], -r[1], r[0], 0, 0]);
}

export function translatePoint(pt, x = 0, y = 0) {
  return transformPt(pt, [1, 0, 0, 1, x, y]);
}

export function scalePoint(pt, xscale = 1, yscale = xscale) {
  return transformPt(pt, [xscale, 0, 0, yscale, 0, 0]);
}

export function sheetScaleFactor(limits, rotation, size) {
  const x = rotation % 180 === 0 ? 0 : 1;
  const lxmin = Math.min(limits[0][x], limits[1][x]);
  const lxmax = Math.max(limits[0][x], limits[1][x]);
  const lsize = lxmax - lxmin;

  return size.x / lsize;
}

export function transformSheetPoint(pt, rotation = 0, scaleFactor, tileSize) {
  const ro = rotatePoint(pt, rotation);
  const sc = scalePoint(ro, scaleFactor);
  const t = translations[rotation % 360] || translations[0];
  const tr = translatePoint(sc, tileSize.x * t[0], tileSize.y * t[1]);
  return { x: tr.x, y: tileSize.y - tr.y };
}

export function transformTilePoint(pt, rotation = 0, scaleFactor, tileSize) {
  const p = { x: pt.x, y: tileSize.y - pt.y };
  const t = translations[rotation % 360] || translations[0];
  const tr = translatePoint(p, -tileSize.x * t[0], -tileSize.y * t[1]);
  const sc = scalePoint(tr, 1 / scaleFactor);
  return rotatePoint(sc, -rotation);
}
