import { bucketArray } from "overline";
import type { OrderedMap } from "../ordered-map.js";
import type { TablesGenerated } from "../database.js";
import type { ItemRecordCore } from "../entities/item.js";

interface Job {
  location_coordinates: {
    longitude: number;
    latitude: number;
  } | null;
  [key: string]: any;
}

interface PricingGroupProperties {
  area: number,
  weight: number,
  quantity: number,
}

interface JobProperties {
  _ungrouped: PricingGroupProperties,
  [key: string]: PricingGroupProperties,
}

interface Supplier {
  address_coordinates: {
    longitude: number;
    latitude: number;
  } | null;
  [key: string]: any;
}

interface Group {
  items: OrderedMap<string, ItemRecordCore>;
  types: OrderedMap<string, Type>;
  [key: string]: any;
}

type Type = TablesGenerated<"types">;
type Item = TablesGenerated<"items">;
type ProductList = TablesGenerated<"product_lists">;
type PriceEntry = TablesGenerated<"price_entries">;

interface ProductPrices {
  [key: string]: Array<PriceEntry>;
}

interface PriceLibrary {
  [key: string]: ProductPrices;
}

function pricingGroupProps(group: Group) {
  const jp: JobProperties = {
    _ungrouped: {
      area: 0,
      weight: 0,
      quantity: 0,
    },
  };

  if (!group) return jp;

  group.items.order.forEach((id) => {
    const item = group.items[id];
    if (!item.is_collection) {
      const type = group.types[item.type_id];
      if (!type || !type?.pricing_group) {
        jp._ungrouped.area += item.cache?.area || 0;
        jp._ungrouped.weight += item.cache?.weight || 0;
        jp._ungrouped.quantity += item.quantity || 0;
      } else if (type?.pricing_group) {
        if (!jp[type.pricing_group]) {
          jp[type.pricing_group] = {
            area: 0,
            weight: 0,
            quantity: 0,
          };
        }
        jp[type.pricing_group].area += item.cache?.area || 0;
        jp[type.pricing_group].weight += item.cache?.weight || 0;
        jp[type.pricing_group].quantity += item.quantity || 0;
      }
    }
  });

  return jp;
}

function orderProductLists(productLists: Array<ProductList>) {
  const assignments = bucketArray(productLists, "parent_id");
  const unparented = productLists.filter((pl) => !pl.parent_id);
  return unparented.reduce((ordered, pl) => {
    ordered.push(pl);

    if (assignments[pl.id]) {
      ordered.push(...assignments[pl.id]);
    }

    return ordered;
  }, []);
}

function makePriceLibrary(priceEntries: Array<PriceEntry>) {
  const library: PriceLibrary = {};

  return priceEntries.reduce((acc, e) => {
    if (!acc[e.product_id]) {
      acc[e.product_id] = {};
    }

    if (!acc[e.product_id][e.list_id]) {
      acc[e.product_id][e.list_id] = [];
    }

    acc[e.product_id][e.list_id].push(e);

    return acc;
  }, library);
}

function itemArea(item: ItemRecordCore) {
  return item.width && item.height ? (item.cache.width / 12) * (item.cache.height / 12) : 0;
}

function findConstrainedEntry(entries: Array<PriceEntry>, item: ItemRecordCore, jp: PricingGroupProperties) {
  return entries?.find((e) => {
    if (e.minimum_area != null && jp) {
      if (jp.area < e.minimum_area) return false;
    }

    if (e.maximum_area != null && jp) {
      if (jp.area >= e.maximum_area) return false;
    }

    if (e.minimum_item_quantity != null) {
      if (item.quantity < e.minimum_item_quantity) return false;
    }

    if (e.maximum_item_quantity != null) {
      if (item.quantity >= e.maximum_item_quantity) return false;
    }

    if (e.minimum_item_area != null) {
      if (itemArea(item) < e.minimum_item_area) return false;
    }

    if (e.maximum_item_area != null) {
      if (itemArea(item) >= e.maximum_item_area) return false;
    }

    return true;
  });
}

function itemPriceEntries(
  item: ItemRecordCore,
  type: Type, priceLibrary: PriceLibrary,
  jobProperties: JobProperties,
  listId: string) {
  const jp = type?.pricing_group ? jobProperties[type.pricing_group] : jobProperties._ungrouped;

  const itemProdEntries = priceLibrary[type?.product_id]?.[listId];

  if (itemProdEntries?.some((e) => e.unit_price != null)) {
    const entry = findConstrainedEntry(itemProdEntries, item, jp);
    return entry ? [entry] : [];
  }

  if (type?.product_spec?.makeup) {
    const makeupEntries = priceLibrary[type.product_spec.makeup.value]?.[listId];
    if (makeupEntries?.some((e) => e.unit_price != null)) {
      const entry = findConstrainedEntry(makeupEntries, item, jp);
      return entry ? [entry] : [];
    }
  }

  const entries = [] as Array<PriceEntry>;
  if (type.data?.layers) {
    type.data.layers.forEach((layer) => {
      if (layer.material) {
        const prodEntries = priceLibrary[layer.material.id]?.[listId];
        const entry = findConstrainedEntry(prodEntries, item, jp);
        if (entry) entries.push(entry);
      }

      if (layer.inboard_surface) {
        const prodEntries = priceLibrary[layer.inboard_surface.id]?.[listId];
        const entry = findConstrainedEntry(prodEntries, item, jp);
        if (entry) entries.push(entry);
      }

      if (layer.outboard_surface) {
        const prodEntries = priceLibrary[layer.outboard_surface.id]?.[listId];
        const entry = findConstrainedEntry(prodEntries, item, jp);
        if (entry) entries.push(entry);
      }
    });
  }

  return entries;
}

interface Fabrication {
  type: string;
  fab_id?: string;
}

function getFabPriceEntry(fab: Fabrication, priceEntry: PriceEntry, priceLibrary: PriceLibrary) {
  if (!fab) return null;

  // This uses hard-coded ids for the system fabrications. Currently ALL edge fabrications
  // are priced according to the "Generic Edge Fabrication" price entry, and ALL corner
  // fabrications are priced according to the "Generic Corner Fabrication" price entry.

  const fabId = {
    "circular-hole": "4b782348-f2d6-5e5c-891c-eac7607ed815",
    "rectangular-hole": "77facaa3-1420-56cf-b83a-93c87ea024f5",
    "edge-fabrication": "a3690b99-0157-58de-b3ae-8742251079f9",
    "corner-fabrication": "cb40dd9d-dbc3-58d2-b5ee-a7b60c1e052c",
  }[fab.type];

  if (!fabId) return null;

  if (priceEntry.fabrication_price_entries) {
    const entry = priceEntry.fabrication_price_entries.find((e) => e.fabrication_id === fabId);
    if (entry) return entry;
  }

  return priceLibrary[fabId]?.[priceEntry.list_id]?.[0] ?? null;
}

function edgeTreatmentFabEntry(id: string, productList: ProductList, priceEntry: PriceEntry | undefined, priceLibrary: PriceLibrary) {
  if (!id) return null;
  if (priceEntry?.edge_treatment_price_entries) {
    const entry = priceEntry.edge_treatment_price_entries.find((e) => e.edge_treatment_id === id);
    if (entry) return entry;
  }

  return priceLibrary[id]?.[productList?.id] ?? null;
}

function getPrice(item: ItemRecordCore, entry: PriceEntry | undefined | null, priceLibrary: PriceLibrary) {
  if (!entry) return 0;
  if (!entry.unit_price || !entry.unit) return 0;

  let price = 0;
  if (entry.unit === "item") {
    price = entry.unit_price;
  } else if (entry.unit === "sqft") {
    price = entry.unit_price * itemArea(item);
  } else if (entry.unit === "sqin") {
    price = entry.unit_price * itemArea(item) * 144;
  } else if (entry.unit === "m2") {
    price = entry.unit_price * itemArea(item) * 0.092903;
  }

  if (!item.data.fabrications) return price;

  let fabPrice = 0;

  item.data.fabrications.voids?.forEach((v) => {
    const e = getFabPriceEntry(v, entry, priceLibrary);
    fabPrice += e?.unit_price || 0;
  });

  item.data.fabrications.edge?.forEach((v) => {
    const e = getFabPriceEntry(v, entry, priceLibrary);
    fabPrice += e?.unit_price || 0;
  });

  item.data.fabrications.corner?.forEach((v) => {
    const e = getFabPriceEntry(v, entry, priceLibrary);
    fabPrice += e?.unit_price || 0;
  });

  item.data.fabrications.virtual?.forEach((v) => {
    const e = getFabPriceEntry(v, entry, priceLibrary);
    fabPrice += e?.unit_price || 0;
  });

  return price + fabPrice;
}

/*
This function calculates the price of edge treatments for a given item.

1. Create a map of edge treatment prices, merging generic edge treatment prices,
   makeup-specific edge treatment prices, and item-specific edge treatment prices.
2. Loop through every layer of the item. If the layer is a glass or generic layer,
   check whether the layer has any defined edge treatments. If so, use them; if not,
   fall back to the edge treatment price map.

*/

function edgeTreatmentPrice(
  item: ItemRecordCore,
  type: Type,
  productList: ProductList,
  priceLibrary: PriceLibrary,
  jobProperties: JobProperties,
  entries: Array<PriceEntry>) {
  if (!item || !type) return 0;
  if (!item.data.fabrications?.edge_treatments) return 0;
  if (!type?.data.layers) return 0;

  const jp = type?.pricing_group ? jobProperties[type.pricing_group] : jobProperties._ungrouped;

  const ownItemEntries = priceLibrary[type.product_id]?.[productList.id];
  const ownItemEntry = findConstrainedEntry(ownItemEntries, item, jp);

  const pe = ownItemEntry?.edge_treatment_price_entries?.reduce((acc, e) => {
    acc[e.edge_treatment_id] = e;
    return acc;
  }, {}) || {};

  const treatments = item.data.fabrications.edge_treatments.reduce((acc, id) => {
    if (!acc[id]) {
      const entries = priceLibrary[id]?.[productList.id];
      const entry = findConstrainedEntry(entries, item, jp);
      if (entry) {
        acc[id] = entry;
      }
    }

    return acc;
  }, pe);

  let price = 0;

  const productPrices = entries.reduce((acc, e) => {
    acc[e.product_id] = e.edge_treatment_price_entries.reduce((p, et) => {
      p[et.edge_treatment_id] = et;
      return p;
    }, {});
    return acc;
  }, {});

  item.data.fabrications.edge_treatments?.forEach((id, edgeIndex) => {
    type.data.layers.forEach((layer) => {
      if (layer.type !== "glass") return;
      const e = productPrices[layer.material?.id]?.[id] || treatments[id];

      if (e?.unit_price && item.width && item.height) {
        const len = item.cache.edge_lengths[edgeIndex] || 0;
        if (e.unit === "in") {
          price += e.unit_price * len;
        } else if (e.unit === "ft") {
          price += e.unit_price * (len / 12);
        } else if (e.unit === "m") {
          price += e.unit_price * (len / 39.3701);
        } else if (e.unit === "each") {
          price += e.unit_price;
        }
      }
    });

  });

  return price;
}

function getItemPrices(
  group: Group,
  productList: ProductList,
  priceLibrary: PriceLibrary,
  jobProperties: JobProperties) {
  if (!group || !productList || !jobProperties) return {};

  return group.items.order.reduce((acc, itemId) => {
    const item = group.items[itemId];
    if (!item) return acc;

    if (item.price_override != null) {
      acc[item.id] = item.price_override / 100;
      return acc;
    }

    const type = group.types[item.type_id];
    if (!type?.product_id) return acc;

    const entries = itemPriceEntries(item, type, priceLibrary, jobProperties, productList.id);
    const etPrice = edgeTreatmentPrice(item, type, productList, priceLibrary, jobProperties, entries);
    const prices = entries.map((entry) => getPrice(item, entry, priceLibrary));
    const priceEach = entries.length ? prices.reduce((acc, p) => acc + p, 0) + etPrice : null;

    acc[item.id] = priceEach;

    return acc;
  }, {} as Record<string, number>);
}

function priceSubtotal(
  group: Group,
  items: Array<ItemRecordCore>,
  productList: ProductList,
  priceLibrary: PriceLibrary,
  jobProperties: JobProperties,
) {
  const itemPriceObj = getItemPrices(group, productList, priceLibrary, jobProperties);
  const itemPrices = items.reduce((acc, item) => acc + (itemPriceObj[item.id] || 0), 0);
  const quoteItemPrices = group.quote_items.reduce((sum, qi) => sum + qi.total_price, 0);
  return itemPrices + quoteItemPrices;
}

export type { ProductList, PriceEntry, PriceLibrary };
export {
  pricingGroupProps,
  itemPriceEntries,
  makePriceLibrary,
  orderProductLists,
  getItemPrices,
  priceSubtotal,
};
