import { Drawing } from "drawing";
import { midpoint } from "vector";
import { edgePoint } from "../geometry/edge-point-rotation.js";

export function twoPoint(fab, instance, edges) {
  const edge1 = edges[instance.reference1.loop]?.[instance.reference1.edge];
  const edge2 = edges[instance.reference2.loop]?.[instance.reference2.edge];

  const { point: p1 } = edgePoint(edge1, instance.reference1);
  const { point: p2 } = edgePoint(edge2, instance.reference2);

  const pts = new Drawing()
    .point(p1, "circle-crosshairs")
    .point(p2, "circle-crosshairs")
    .style({ stroke: "red", fill: "transparent" });

  const line = new Drawing()
    .polyline([p1, p2])
    .style({ stroke: "#333", fill: "transparent", lineDash: [3, 2] });

  const annotations = new Drawing().add(pts, line);

  const point = midpoint(p1, p2);

  return {
    point,
    path: null,
    voids: [],
    dims: () => [],
    annotations,
  };
}
