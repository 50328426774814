import { darken } from "drawing";
import { shapeColor } from "./type-colors.js";
import colorLuma from "./color-luma.js";

function typeColor(type) {
  if (type.data?.layers?.length > 0) {
    const l0 = type.data.layers[0];
    const l1 = type.data.layers[1];

    let color = shapeColor(l0, l1);

    if (color) {
      const l = colorLuma(color);
      if (l < 200) {
        const pct = (200 - l) / 255;
        color = darken(pct, color);
      }
      return color;
    }
  }

  return "#B7D7F7";
}

function typeColorTrue(type) {
  if (type.data?.layers?.length > 0) {
    const l0 = type.data.layers[0];
    const l1 = type.data.layers[1];
    return shapeColor(l0, l1);
  }

  return null;
}

export default typeColor;
export { typeColorTrue, typeColor };
