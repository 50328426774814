import { Drawing } from "drawing";
import { fabFunctions } from "../fabrications/index.js";

export default function fabDrawing(
  fab = {},
  {
    showDims = false,
    extend = true,
    displayUnit = "inches",
    dimFormat = "DECIMAL",
    dimPrecision = 2,
    highlights = {},
  } = {},
) {
  const dimConversion = displayUnit === "millimeters" ? 25.4 : 1;

  const dimstyle = {
    stroke: "#888888",
    annoFormat: dimFormat,
    annoPrecision: dimPrecision,
    dimConversion,
  };

  const hdimstyle = {
    stroke: "#FF0000",
    textColor: "#FF0000",
    annoFormat: dimFormat,
    annoPrecision: dimPrecision,
    dimConversion,
  };

  let path;
  let voids;
  let dims;
  if (fab.type === "function") {
    const func = fabFunctions[fab.func];
    const res = func(...fab.params);
    path = res.path;
    voids = res.voids;
    const fdims = res.dims && showDims ? res.dims() : [];

    dims = fdims.map((d) => {
      const style = highlights[d.id] ? hdimstyle : dimstyle;
      return d.dim.style(style).name(`dim_${d.id}`);
    });
  } else {
    path = fab.path;
    voids = fab.voids;
    dims = [];
  }

  const zero = new Drawing()
    .point({ x: 0, y: 0 }, "circle-crosshairs")
    .style({ stroke: "red", fill: "transparent" });

  if (!path) {
    let shape = new Drawing()
      .polyline([
        { x: -1, y: 0 },
        { x: 1, y: 0 },
      ])
      .style({ stroke: "transparent" });

    let bkd;
    let positions = [];

    if (fab.position === "edge") {
      const tone = new Drawing()
        .polygon([
          { x: -1, y: 0 },
          { x: 1, y: 0 },
          { x: 1, y: 1 },
          { x: -1, y: 1 },
        ])
        .style({ stroke: "transparent", fill: "#DAE3F0" })
        .skipBbox();

      const line = new Drawing()
        .polyline([
          { x: -1, y: 0 },
          { x: 1, y: 0 },
        ])
        .skipBbox();

      const dash = new Drawing()
        .polyline([
          { x: -0.5, y: 0 },
          { x: -0.5, y: 0.5, fillet: 0.1 },
          { x: 0.5, y: 0.5, fillet: 0.1 },
          { x: 0.5, y: 0 },
        ])
        .style({ lineDash: [5, 3], lineWidth: 1 })
        .skipBbox();

      bkd = new Drawing().add(tone, line, dash);
      positions = [zero];
    } else if (fab.position === "corner") {
      const tone = new Drawing()
        .polygon([
          { x: 0, y: 0 },
          { x: 1, y: 0 },
          { x: 1, y: 1 },
          { x: 0, y: 1 },
        ])
        .style({ stroke: "transparent", fill: "#DAE3F0" })
        .skipBbox();

      const line = new Drawing()
        .polyline([
          { x: 0, y: 1 },
          { x: 0, y: 0 },
          { x: 1, y: 0 },
        ])
        .skipBbox();

      const dash = new Drawing()
        .polyline([
          { x: 0, y: 0.5 },
          { x: 0.5, y: 0.5, fillet: 0.1 },
          { x: 0.5, y: 0 },
        ])
        .style({ lineDash: [5, 3], lineWidth: 1 })
        .skipBbox();

      bkd = new Drawing().add(tone, line, dash);
      positions = [zero];
    } else if (fab.position === "edge-offset") {
      const tone = new Drawing()
        .polygon([
          { x: -1, y: -0.25 },
          { x: 1, y: -0.25 },
          { x: 1, y: 1 },
          { x: -1, y: 1 },
        ])
        .style({ stroke: "transparent", fill: "#DAE3F0" })
        .skipBbox();

      const line = new Drawing()
        .polyline([
          { x: -1, y: -0.25 },
          { x: 1, y: -0.25 },
        ])
        .skipBbox();

      bkd = new Drawing().add(tone, line);
      positions = [zero];
    } else if (fab.position === "edge-aligned") {
      const tone = new Drawing()
        .polygon([
          { x: -0.75, y: 0 },
          { x: 0.75, y: 0 },
          { x: 0.75, y: 1 },
          { x: -0.75, y: 1 },
        ])
        .style({ stroke: "transparent", fill: "#DAE3F0" })
        .skipBbox();

      const line = new Drawing()
        .polyline([
          { x: -0.75, y: 1 },
          { x: -0.75, y: 0 },
          { x: 0.75, y: 0 },
          { x: 0.75, y: 1 },
        ])
        .skipBbox();

      bkd = new Drawing().add(tone, line);
      positions = [
        new Drawing()
          .polyline([
            { x: -0.75, y: 0 },
            { x: 0.75, y: 0 },
          ])
          .style({ stroke: "red", lineWidth: 2 }),
      ];
    } else if ((fab.position = "two-point")) {
      bkd = new Drawing()
        .polygon([
          { x: -1, y: -1 },
          { x: 1, y: -1 },
          { x: 1, y: 1 },
          { x: -1, y: 1 },
        ])
        .style({ stroke: "transparent", fill: "#DAE3F0" })
        .skipBbox();

      positions = [
        new Drawing()
          .point({ x: -0.6, y: -0.6 }, "circle-crosshairs")
          .style({ stroke: "red", fill: "transparent" }),
        new Drawing()
          .point({ x: 0.6, y: 0.6 }, "circle-crosshairs")
          .style({ stroke: "red", fill: "transparent" }),
        new Drawing()
          .polyline([
            { x: -0.6, y: -0.6 },
            { x: 0.6, y: 0.6 },
          ])
          .style({ stroke: "red", lineWidth: 2 }),
      ];
    }

    return new Drawing().add(shape, bkd, ...positions);
  }

  if (!fab.position || fab.position === "edge") {
    const shape = new Drawing().polyline(path).style({ lineWidth: 2 });
    const voidDwgs = voids.map((v) =>
      new Drawing()
        .polygon(v.path)
        .style({ lineWidth: 2, fill: "transparent" }),
    );
    const first = path[0];
    const last = path[path.length - 1];

    const bbox = shape.bbox;
    const width = bbox.xmax - bbox.xmin;
    const height = bbox.ymax - bbox.ymin;
    const minH = height * 1.3;
    const ar = width / minH;
    const ext = ar > 1 ? width * 0.3 : Math.max(minH * 0.3, (minH - width) / 2); // how far to extend lines past edge of fabrication?
    const xmin = bbox.xmin - ext;
    const xmax = bbox.xmax + ext;
    const ymax = extend ? bbox.ymax + 5 * ext : bbox.ymax + ext;
    const lv = { x: xmin, y: first.y };
    const rv = { x: xmax, y: last.y };

    const l = new Drawing().polyline([lv, first]).style({ lineWidth: 0.5 });
    const r = new Drawing().polyline([rv, last]).style({ lineWidth: 0.5 });

    const tone = new Drawing()
      .polygon([lv, ...path, rv, { x: xmax, y: ymax }, { x: xmin, y: ymax }])
      .style({ stroke: "transparent", fill: "#DAE3F0" })
      .skipBbox();

    return new Drawing().add(tone, l, r, shape, ...voidDwgs, zero, ...dims);
  } else if (fab.position === "corner") {
    const shape = new Drawing().polyline(path).style({ lineWidth: 2 });
    const voidDwgs = voids.map((v) =>
      new Drawing()
        .polygon(v.path)
        .style({ lineWidth: 2, fill: "transparent" }),
    );
    const first = path[0];
    const last = { x: path[path.length - 1].x, y: path[path.length - 1].y };
    const bbox = shape.bbox;
    const width = bbox.xmax - bbox.xmin;
    const ext = width * 0.3;
    const xmin = bbox.xmin - ext;
    const xmax = bbox.xmax + ext;
    const ymax = extend ? bbox.ymax + 5 * ext : bbox.ymax + ext;
    const spacer = new Drawing()
      .polyline([
        { x: xmin, y: 0 },
        { x: bbox.xmin, y: 0 },
        { x: bbox.xmin, y: bbox.ymax + ext },
      ])
      .style({ stroke: "transparent" });

    const lv = { x: bbox.xmin, y: ymax };
    const rv = { x: xmax, y: last.y };
    const l = new Drawing()
      .polyline([lv, first])
      .style({ lineWidth: 0.5 })
      .skipBbox();
    const r = new Drawing().polyline([rv, last]).style({ lineWidth: 0.5 });

    const tone = new Drawing()
      .polyface([
        [lv, ...path, rv, { x: xmax, y: ymax }],
        ...voids.map((v) => v.path),
      ])
      .style({ stroke: "transparent", fill: "#DAE3F0" })
      .skipBbox();

    return new Drawing().add(
      tone,
      l,
      r,
      spacer,
      shape,
      ...voidDwgs,
      zero,
      ...dims,
    );
  }
}
