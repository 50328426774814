<script>
  import { createEventDispatcher } from "svelte";
  import EllipsisIcon from "@local/assets/icons/ellipsis.svg";
  import ExitIcon from "@local/assets/icons/exit.svg";
  import TrashIcon from "@local/assets/icons/trash.svg";
  import DuplicateIcon from "@local/assets/icons/duplicate.svg";
  import DuplicateSendIcon from "@local/assets/icons/duplicate-send.svg";
  import ArchiveIcon from "@local/assets/icons/archive.svg";
  import UnarchiveIcon from "@local/assets/icons/unarchive.svg";
  import { clickOutside, Popover } from "svelte-utilities";

  export let datum;
  export let actionsComponent = null;
  export let deletable = false;
  export let archivable = false;
  export let unarchivable = false;
  export let cloneable = false;
  export let sendable = false;
  export let visitable = false;

  const dispatch = createEventDispatcher();

  let show = false;
  let hamburger;
  let popover;

  function toggleDropdown() {
    show = !show;
  }

  function click(value) {
    show = false;
    dispatch("click", { value });
  }
</script>

<button class="relative py-1.5" on:click|stopPropagation={toggleDropdown} bind:this={hamburger}>
  <EllipsisIcon />
  {#if show}
    <Popover container={hamburger} align="right">
      <div
        class="relative p-2 z-40 bg-white rounded text-xs text-black"
        bind:this={popover}
        use:clickOutside={[hamburger]}
        on:outclick={() => (show = false)}>
        {#if actionsComponent}
          <svelte:component this={actionsComponent} {datum} on:click={(e) => click(e.detail.value)} />
        {/if}
        <slot />
        {#if visitable}
          <button class="dropdown-button-item" on:click={() => click("gotorow")}>
            <ExitIcon />
            <div>Go To</div>
          </button>
        {/if}
        {#if cloneable}
          <button class="dropdown-button-item" on:click={() => click("clonerow")}>
            <DuplicateIcon />
            <div>Clone</div>
          </button>
        {/if}
        {#if sendable}
          <button class="dropdown-button-item" on:click={() => click("sendrow")}>
            <DuplicateSendIcon />
            <div>Clone to Org</div>
          </button>
        {/if}
        {#if deletable}
          <button class="dropdown-button-item" on:click={() => click("deleterow")}>
            <TrashIcon />
            <div>Delete</div>
          </button>
        {/if}
        {#if archivable}
          <button class="dropdown-button-item" on:click={() => click("archiverow")}>
            <ArchiveIcon />
            <div>Archive</div>
          </button>
        {/if}
        {#if unarchivable}
          <button class="dropdown-button-item" on:click={() => click("unarchiverow")}>
            <UnarchiveIcon />
            <div>Unarchive</div>
          </button>
        {/if}
      </div>
    </Popover>
  {/if}
</button>
