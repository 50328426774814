<script>
  import { onMount } from "svelte";
  import { draggable, Portal } from "svelte-utilities";

  let parent;
  export let z = 210;
  let ticking = false;
  let bbox;

  $: getBbox(parent);

  function getBbox(parent) {
    if (!parent) {
      bbox = { l: 0, t: 0, w: 0, h: 0 };
      return;
    }

    const c = parent.getBoundingClientRect();
    bbox = { l: c.left, t: c.top, w: c.width, h: c.height };
  }

  function updateBbox() {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        getBbox(parent);
        ticking = false;
      });

      ticking = true;
    }
  }

  onMount(() => {
    window.addEventListener("scroll", updateBbox, true);
    window.addEventListener("resize", updateBbox, true);

    return () => {
      window.removeEventListener("scroll", updateBbox, true);
      window.removeEventListener("resize", updateBbox, true);
    };
  });
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<Portal bind:parent>
  <div
    style="
    position: fixed;
    pointer-events: none;
    z-index: {z};
    top: {bbox.t}px;
    left: {bbox.l}px;
    width: {bbox.w}px;
    height: {bbox.h}px;
  ">
    <div class="container" use:draggable on:drag on:dragstart on:dragend>
      <div class="inner" />
    </div>
  </div>
</Portal>

<style>
  div.container {
    pointer-events: auto;
    position: absolute;
    z-index: 20;
    right: -5px;
    bottom: -5px;
    width: 8px;
    height: 8px;
    padding: 1px;
    cursor: crosshair;
    @apply bg-white;
  }

  div.inner {
    width: 100%;
    height: 100%;
    @apply bg-blue-500;
  }
</style>
