<script>
  import { timeout } from "overline";
  import isEqual from "lodash/isEqual";
  import { createEventDispatcher } from "svelte";
  import CheckIcon from "@local/assets/icons/check.svg";
  import XIcon from "@local/assets/icons/x.svg";
  import CaretDownIcon from "@local/assets/icons/caret-down.svg";
  import { clickOutside, Popover } from "svelte-utilities";

  export let border = false;
  export let options = [];
  export let label = "Label";
  export let value;
  /** @type {string | null} */
  export let labelWidth = null;
  export let disabled = false;
  export let readonly = false;
  export let placeholder = null;
  export let outerBorder = true;
  export let showCaret = false;
  export let deletable = false;

  const dispatch = createEventDispatcher();

  let containers = [];
  let input;
  let popover;
  let focused = false;
  let showOptions = false;
  let selectedOption = null;

  $: lw = labelWidth ? `width:${labelWidth};` : "";
  $: currentOption = options.find((o) => isEqual(o.value, value));
  $: containers = [input, popover];

  function focus(e) {
    if (disabled) return;
    focused = true;
    input.focus();
    dispatch("focus", e);
    selectedOption = null;
    showOptions = true;
  }

  function chooseOption(option) {
    if (!option) {
      return blur();
    }
    const index = options.findIndex((o) => o.value === option.value);
    if (index > -1) {
      selectedOption = index;
      selectedOption = index;
      value = option.value;
      dispatch("input", { value: option.value });
    }
    dispatch("mouseout");
    blur();
  }

  function keydown(evt) {
    switch (evt.key) {
      case "Enter":
        if (!showOptions) {
          selectedOption = null;
          showOptions = true;
        } else if (options[selectedOption]) {
          chooseOption(options[selectedOption]);
        }
        break;
      case "ArrowDown":
        evt.preventDefault();
        showOptions = true;
        if (selectedOption === null) {
          selectedOption = 0;
        } else if (options.length > selectedOption + 1) {
          selectedOption += 1;
        }
        break;
      case "ArrowUp":
        evt.preventDefault();
        if (selectedOption > 0) {
          selectedOption -= 1;
        }
        break;
    }
  }

  function deleteOption(option) {
    dispatch("delete-option", { value: option.value });
  }

  async function blur(e) {
    if (e && e.type === "blur") {
      await timeout();

      if (popover && popover.contains(document.activeElement)) {
        return;
      }
    } else if (e && e.type === "outclick") {
      if (popover && popover.contains(document.activeElement)) {
        return;
      }
    }

    focused = false;
    if (input) input.blur();
    showOptions = false;
  }
</script>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="prop-container"
  class:has-outer-border={outerBorder}
  class:has-inner-border={!outerBorder}
  class:focused
  disabled={readonly || disabled}
  class:readonly={readonly || disabled}
  class:visible-border={border}
  on:click={focus}
  on:keydown={keydown}
  tabindex={disabled || readonly ? -1 : 0}
  bind:this={input}
  on:blur={blur}
  on:mouseover
  on:mouseout
  on:focus={focus}
  use:clickOutside
  on:outclick={blur}>
  {#if label !== null}
    <div style={lw} class="flex-none">
      <div class="label">
        <slot name="label">
          {label}
        </slot>
      </div>
    </div>
  {/if}
  <div class="input-div">
    <div class="input" class:disabled>
      {#if value === "Mixed"}
        Mixed
      {:else if currentOption}
        <slot name="selected" option={currentOption}>
          {currentOption.label}
        </slot>
      {:else}
        <span class="text-gray-400">{placeholder || ""}</span>
      {/if}
    </div>
    {#if !disabled}
      <div class="flex-none caret-down-icon" class:show-caret={showCaret}>
        <CaretDownIcon />
      </div>
    {/if}
  </div>
  {#if showOptions}
    <Popover container={input} fit>
      <div class="w-full py-1 text-xs overflow-hidden" bind:this={popover} tabindex="0">
        {#each options as option, index}
          <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
          <li
            class="option-item flex p-1 hover:bg-gray-100 items-center cursor-pointer"
            class:selected={index === selectedOption}
            on:mousedown|stopPropagation
            on:keydown={() => chooseOption(option)}
            on:click|stopPropagation={() => chooseOption(option)}>
            <div class="flex-none w-6">
              {#if option.value === value}
                <CheckIcon />
              {/if}
            </div>
            <slot name="option" {option}>
              <div class="truncate">{option.label}</div>
            </slot>
            {#if deletable && option.deletable !== false}
              <button
                class="ml-auto delete-button rounded hover:bg-gray-200"
                on:click|stopPropagation={() => deleteOption(option)}>
                <XIcon />
              </button>
            {/if}
          </li>
        {/each}
      </div>
    </Popover>
  {/if}
</div>

<style lang="scss">
  .prop-container {
    @apply w-full flex items-center rounded relative bg-white overflow-hidden;
    outline: none;

    .input-div {
      @apply ml-2 relative grow truncate flex items-center;
    }

    .caret-down-icon:not(.show-caret) {
      visibility: hidden;
    }

    &.has-outer-border {
      @apply p-2;

      &:hover:not(.readonly):not(.focused),
      &.visible-border {
        @apply ring-1 ring-inset ring-gray-300;
      }
      &.focused {
        @apply ring-2 ring-inset ring-blue-500;
      }

      .caret-down-icon {
        visibility: visible;
      }
    }

    &.has-inner-border {
      .input-div {
        @apply p-1 rounded;
      }

      &:hover {
        .caret-down-icon {
          visibility: visible;
        }
      }
    }

    &.has-inner-border:not(.readonly):not(.focused) {
      .input-div:hover {
        @apply ring-1 ring-inset ring-gray-300;
      }
    }

    .label {
      @apply truncate text-gray-400;
    }

    .input {
      @apply min-w-0 grow bg-transparent truncate;
    }
  }

  li.option-item.selected {
    @apply bg-blue-400 text-white;
  }

  .option-item {
    .delete-button {
      display: none;
    }
  }

  .option-item:hover {
    .delete-button {
      display: block;
    }
  }
</style>
